import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

function BgVideo() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

    return (
        <div className="col-12">
            <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/logo/logo2.png)"}}>
                <div className="s-video-content mb-80">
                    <div className="popup-video mb-50" onClick={handleShow}>
                        <i className="fas fa-play"></i>
                    </div>
                    <h2 style={{color: '#333'}}>SAB 2023</h2>
                    <p style={{color: 'black'}}>Teaser</p>
                </div>
            </div>

            <Modal show={show} size='lg' onHide={handleClose} style={{top: '20%'}}>
                <Modal.Body>
                    <video width="auto" height="auto" controls autoPlay>
                        <source src="/assets/video/SAB 2023.mp4" type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BgVideo
