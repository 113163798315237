import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import { Modal } from 'react-bootstrap';
import '../../node_modules/photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const items = [
    {
        src: "/assets/img/d-fit/1.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/2.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/3.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/4.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/5.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/6.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/7.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/8.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/9.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/10.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/11.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/12.jpg",
        width: 600,
        height: 600
    },
    {
        src: "/assets/img/d-fit/13.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/14.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/15.jpg",
        width: 800,
        height: 600
    },
    {
        src: "/assets/img/d-fit/16.jpg",
        width: 800,
        height: 600
    }
]

const options = {
    arrowPrev: true,
    arrowNext: true,
    zoom: false,
    close: true,
    counter: true,
    bgOpacity: 0.6
}

function DFitContainer() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

    function shuffleArray(array) {
        const shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }

    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="D-FIT" subtitle="D-FIT" />

            <section id="about" className="about-area about-p pt-120 pb-120 p-relative">
                <div className="container">
                    <div className="row align-items-center">                       
                        <div className="col-lg-6">
                            <div className="about-content s-about-content pl-30">
                                <div className="about-title second-atitle">
                                    <div className="text-outline wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                                        <span>D-FIT</span>
                                    </div>
                                    <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".3s">D-FIT</span>
                                    <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">D-FIT, centre de nutrition, santé et sport</h2>
                                    {/* <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>Shift your perspective on digital business</h5> */}
                                </div>
                                <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                    <p>
                                    Créé en 2018 par Ingrid NGOUNÉ, Diet and Fitness (D-FIT) livre des repas 100% diététiques à base 
                                    de produits du terroir. Première chaine de restauration à intégrer le réseau de distribution TOTAL 
                                    ÉNERGIES Cameroun, mettant à disposition du public un restaurant à même d’accueillir des 
                                    visiteurs, une supérette foisonnante de farines locales, laitages et d’un cabinet chapeauté par une 
                                    équipe d’experts.
                                    </p>
                                    <p>
                                    L’enseigne a entamé le projet de construction de son usine à Douala, capitale 
                                    économique du Cameroun, preuve de leur maîtrise des désirs des populations en quête d’un 
                                    mode de vie à impact positif.
                                    </p>
                                    <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="s-video-wrap">
                                <div className="s-video-content">
                                    <div className="popup-video mb-50" onClick={handleShow}>
                                        <i className="fas fa-play"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><br />
                </div>
            </section>

            <section id="team" className="portfolio-area p-relative pt-120 pb-120 fix">
                <div className="section-t team-t paroller" 
                    data-paroller-factor="0.15" 
                    data-paroller-factor-lg="0.15" 
                    data-paroller-factor-md="0.15" 
                    data-paroller-factor-sm="0.15" 
                    data-paroller-type="foreground" 
                    data-paroller-direction="horizontal"
                ><h2>Galerie</h2></div>				
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center mb-80">
                                <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">galerie</span>
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Notre album</h2>
                            </div>
                        </div>
                    </div>
                    <Gallery options={options}>
                        <ResponsiveMasonry>
                            <Masonry columnsCount={3} gutter="20px">
                                {shuffleArray(items).map((item, i) => (
                                    <Item
                                        original={item.src}
                                        thumbnail={item.src}
                                        width={item.width}
                                        height={item.height}
                                        key={i}
                                    >
                                        {({ ref, open }) => (
                                            <img ref={ref} onClick={open} src={item.src} alt={`Photo-` + i} style={{cursor:'pointer'}} />
                                        )}
                                    </Item>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Gallery>
                </div>
            </section>

            <Modal show={show} size='lg' onHide={handleClose} style={{top: '25%'}}>
                <Modal.Body>
                    <video width="auto" height="auto" controls autoPlay>
                        <source src="/assets/video/D-Fit REPORTAGE.mp4" type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal>
        </main>
    )
}

export default DFitContainer
