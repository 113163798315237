import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function About() {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState("") 
    const handleClose = () => setShow(false);
    const [size, setSize] = useState("")
    const handleShow = (link, size="md") => {
        setUrl(link)
        setShow(true)
        setSize(size)
    }
    let tailleEcranY = window.innerHeight;
    const [height, setHeight] = useState("")
    
    useEffect(() => {
        console.log(tailleEcranY)
        if (tailleEcranY <= 900 && tailleEcranY > 700) {
            setHeight("10%")
        } else if (tailleEcranY <= 700) {
            setHeight("2%")
        } else {
            setHeight("15%")
        }
    }, [])

    return (
        <section id="about" className="about-area about-p pb-120 p-relative">
            <div className="container">
                <div className="row align-items-center">                       
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".3s">Notre évènement</span>
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Pourquoi nous rejoindre ?</h2>
                                {/* <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>Shift your perspective on digital business</h5> */}
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Préparez-vous à embrasser un mode de vie sain et épanouissant ! Le tout nouveau Salon de l'Alimentation Saine et du Bien-Être ouvrira bientôt ses portes, promettant une expérience unique pour tous ceux qui aspirent à un style de vie équilibré.</p>
                                <p>Que vous soyez un passionné de nutrition, un adepte du fitness ou simplement à la recherche d'un havre de paix, ce salon deviendra votre destination incontournable. Des experts chevronnés vous guideront vers des choix alimentaires judicieux et des pratiques favorisant un bien-être holistique.</p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/logo/logo2.png)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/Montage.mp4", "lg")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/veronique_diboua.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/09 VERONIQUE DIBOA - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Rosette IVAHA DIBOUA</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>La Marraine</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Une aura de bravoure émane de son nom. Mme Rosette IVAHA DIBOUA, Première Dame de la Région du 
                                    Littoral, appose son sceau sur l’Escale SAB. Elle coordonne avec justesse l’éclosion d’une vision portée par 
                                    des orfèvres dévoués au raffermissement d’une cause juste et noble.
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Mme Mireille Flore DEFO FOTSO</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>l’Hyper Esthète</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Quand la bonne volonté se fait chair. Mireille Flore DEFO FOTSO, Journaliste éminemment respectée 
                                    mobilise des ressources colossales pour le bon déroulement de cette édition du SAB AFRIQUE 2024.
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/mireille_fotso.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/06 Mireille fotso website - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/adeline_kamga.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/SAB x CEO FABAFRIQ FR - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Adeline SEDE KAMGA</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>Papesse des Relations Publiques</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>Conteuse virtuose capable de déployer des récits auxquels les médias de l’ensemble du continent ne 
                                    peuvent résister, Adeline SEDE KAMGA, Founder de la très courue agence de Relations Publiques 
                                    FABAFRIQ, orchestre le plus poétique des Rendez-Vous à même d’illuminer les savoir-faire d’un continent 
                                    qui rassemble
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">NDOCK BIDI</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span> l’Icône Culinaire</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                Parvenu au sommet par les vertus de son talent et sa grande persévérance, NDOCK BIDI animera le Live 
                                Cooking Show pour partager avec l’assistance sa recette diététique préférée.
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/tresor_nwoula.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/03 Tresor NWOULA website - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/ange_mbayen.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/04 Ange MBAYEN website - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">ANGE MBAYEN</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>Chef d’Œuvre de Raffinement et de Créativité</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                Référence unique en son genre, Ange MBAYEN est l’entrepreneure de tous les superlatifs. Productrice de 
                                la série à succès LES BOLDIES, elle met également sur pied BOLD MAKE UP, enseigne respectueuse de la 
                                carnation et créatrice d’éclat grâce à des formules à la pointe de l’innovation. Sa passion pour la lumière, 
                                fusionnée à son instinct puissant l’érige au pinacle des Artisans dédiés au Bien-être des consommateurs.
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Joël KAMSU</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>l’Excellence du Voyage</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                Expériences uniques en osmose avec la nature, décors époustouflants, tables gastronomiques célébrant 
                                le Cameroun et son Esprit… Le tourisme du Bien-Être se décline à l’infini grâce à l’ingéniosité de Joël 
                                KAMSU, Founder de YENGA AFRICA qui nous promet euphorie et volupté à l’occasion de notre Rendez-vous
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/yenga_africa.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/05 YENGA AFRICA website  - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/tara_sport.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/07 TARA SPORT websie - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Patrick Kofana</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>l’Essence du Sport</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                A la tête de TARA SPORT, centre de marketing sportif, Consulting, Événementiel et Gestion de carrière, 
                                Patrick Kofana déploie chaque année le Marathon International de Douala, accueillant des athlètes de haut 
                                niveau venus des quatre coins du globe. Elle partagera ses acquis incomparables lors du Semi-Marathon
                                agendé par ses soins en cette deuxième édition du SAB AFRIQUE
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Maman KAMITE</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>Artisan du Sublime</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                Trésor du patrimoine culinaire Camerounais, Vanessa KUISSU TABETH MBIALEU, connue sous la 
                                chaleureuse appellation « Maman KAMITE », est la Fondatrice de la plus grande adresse crêpière du pays, 
                                la légendaire « Mmmh La crêperie ». Elle déploiera dans un doux mélange de fierté et d’humilité les plus 
                                belles allégories de son vestiaire à l’orée du SAB AFRIQUE 2024.
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/tata_crepiere.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/08 TATA LA CREPIERE website - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="s-video-wrap" style={{backgroundImage: "url(/assets/img/partners/julia_samantha.jpg)"}}>
                            <div className="s-video-content">
                                <div className="popup-video mb-50" onClick={() => handleShow("/assets/video/02 Julia samantha website - (9x16).mp4")}>
                                    <i className="fas fa-play"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content s-about-content pl-30">
                            <div className="about-title second-atitle">
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Julia Samantha ÉDIMA</h2>
                                <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".5s"><span></span>la Miss de Cœur</h5>
                            </div>
                            <div className="wow fadeInDown animated" data-animation="fadeInUp animated" data-delay=".2s">
                                <p>
                                Celle qui siège au cœur des Miss Cameroun les plus aimées du public marquera l’événement par sa 
                                présence via sa fondation éponyme qui vient en aide aux personnes vulnérables.
                                </p>
                                <a href="/contact" className="btn mt-20"><i className="far fa-ticket-alt"></i> Acheter un ticket</a>
                            </div>
                        </div>
                    </div>
                </div><br />
            </div>

            <Modal show={show} onHide={handleClose} size={size} style={{top: height}}>
                <Modal.Body>
                    <video width="auto" height="auto" controls autoPlay>
                        <source src={url} type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default About
