import axios from 'axios';

const API_BASE_URL = "https://api.sabafrique.com/api" //'http://localhost:3000/api'// // Remplacez par l'URL de votre backend

// Instance Axios pour centraliser la configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000, // Timeout de 10 secondes
});

// Gestion centralisée des erreurs
const handleErrors = (error) => {
  if (error.response) {
    // Erreurs renvoyées par le backend
    console.error('Erreur backend:', error.response.data);
    return { success: false, message: error.response.data.message || 'Erreur serveur.' };
  } else if (error.request) {
    // Aucun retour du serveur
    console.error('Aucune réponse reçue:', error.request);
    return { success: false, message: 'Aucune réponse du serveur. Vérifiez votre connexion.' };
  } else {
    // Erreur lors de la configuration de la requête
    console.error('Erreur lors de la requête:', error.message);
    return { success: false, message: 'Erreur lors de la requête.' };
  }
};

// Méthodes pour les opérations API

// 1. Récupérer tous les articles
export const getAllPosts = async () => {
  try {
    const response = await axiosInstance.get('/posts');
    return { success: true, data: response.data };
   
  } catch (error) {
    return handleErrors(error);
  }
};

// 2. Récupérer un article spécifique
export const getPostById = async (postId) => {
  try {
    const response = await axiosInstance.get(`/posts/${postId}`);
    return { success: true, data: response.data };
  } catch (error) {
    return handleErrors(error);
  }
};

// 3. Poster un nouvel article
export const createPost = async (postData) => {
  try {
    const response = await axiosInstance.post('/posts', postData);
    return { success: true, data: response.data };
  } catch (error) {
    return handleErrors(error);
  }
};

// 4. Récupérer les commentaires d’un article
export const getCommentsByPostId = async (postId) => {
  try {
    const response = await axiosInstance.get(`/comments/${postId}`);
    return { success: true, data: response.data };
  } catch (error) {
    return handleErrors(error);
  }
};

// 5. Ajouter un nouveau commentaire
export const createComment = async (commentData) => {
  try {
    console.log(commentData)
    const response = await axiosInstance.post('/comments', commentData);
    return { success: true, data: response.data };
  } catch (error) {
    return handleErrors(error);
  }
};


