import { useEffect, useState } from "react"
import { getAllPosts } from "../data/api"
import { timeAgo } from "../utils/utils"
import { Link } from "react-router-dom"

export default function Blogs() {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getAllPosts().then(({ success, message, data }) => {
            if (data) setPosts(data)
            
        })
    }, [])

    return (
        <div className="col-lg-8">
            {posts.map((post, i)=><div key={i} className="bsingle__post mb-50 wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                <div className="bsingle__post-thumb">
                   <Link to={`/blog/${post.id}-${post.slug}`}> <a>  <img src={post.cover} alt="" /></a></Link>
                </div>
                <div className="bsingle__content">
                    <div className="meta-info">
                        <ul><li><a><i className="far fa-clock"></i>{timeAgo(post.created_at)}</a></li>
                            <li><a><i className="far fa-eye"></i>{post.views} Vues</a></li>

                            <li><a><i className="far fa-comments"></i>{post.comment_count} Commentaires</a></li>
                        </ul>
                    </div>
                    <h2><Link to={`/blog/${post.id}-${post.slug}`}><a>{post.title}</a></Link></h2>
                    <p>{post.description}</p>
                    <div className="blog__btn">
                    <Link to={`/blog/${post.id}-${post.slug}`}> <a className="btn">Voir plus</a></Link>
                    </div>
                </div>
            </div>)}
            
        </div>
    )
}