import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Banner from '../components/Banner'
import Footer from '../components/Footer'

function Policy() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Politique de confidentialité - SAB Afrique</title>
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
            </Helmet>

            <Header />

            <main>
                <Banner subtitle="Politique de confidentialité" title="Politique de confidentialité" />
                <section className="product-desc-area mt-80 mx-5 mb-80">
                    <h4 className="wow fadeInUp animated text-center mb-4" data-animation="fadeInUp animated" data-delay=".2s">POLITIQUE DE CONFIDENTIALITÉ ET DE TRAITEMENT DES DONNÉES PERSONNELLES</h4>
                    <div className="event-text">
                        <p>La présente Politique de confidentialité vous informe de la manière dont nous recueillons et 
                        traitons vos données personnelles. Nous vous invitons à la lire attentivement.</p>
                        <p>Nous n’utilisons vos données personnelles que dans les cas prévus par la réglementation en 
                        vigueur :</p>
                        <ul style={{color: 'black'}}>
                            <li>* L'exécution d'un contrat que nous avons conclu avec vous, et/ou</li>
                            <li>* Le respect d'une obligation légale, et/ou</li>
                            <li>* Votre consentement à l'utilisation de vos données, et/ou</li>
                            <li>* L'existence d'un intérêt légitime à utiliser vos données. L'intérêt légitime est un ensemble de raisons commerciales ou d'affaires qui justifie l'utilisation de vos données.</li>
                        </ul>
                    </div>

                    <div className='mt-4'>
                        <h6 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">QUI EST LE RESPONSABLE DES TRAITEMENTS MENTIONNÉS DANS LE PRÉSENT DOCUMENT ?</h6>
                        <p>Le responsable des traitements mentionnés par le présent document est <b>DIET AND FITNESS (D-FIT)</b>, Société à Responsabilité Limitée au capital de 950.000 Francs CFA, immatriculée au registre 
                            du commerce du Cameroun sous le n° <b>RC DLA /2022/B/1648</b> dont le siège social est situé à Akwa, 
                            Douala et dont <b>Madame MOUAFO NGOUNE Ingrid Delchelle</b> est la représentante légale.</p>
                        <div style={{marginTop: 30}}>
                            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>ARTICLE 1. OBJET</h5>
                            <p>La présente politique d'utilisation des données a pour objet de décrire de manière claire et 
                            transparente la façon dont <b>NGANDÉLÉ Sarl</b>, en tant que responsable de traitement traite les 
                            données à caractère personnel. Elle s’applique à l’ensemble des traitements de données à caractère personnel effectués par <b>DIET 
                            AND FITNESS (D-FIT)</b> à partir de ses sites internet, applications mobiles, lors d’évènements ou 
                            salons professionnels et pour toute opération d’actions de marketing direct ou études marketing.
                            </p>
                            <p>Constitue une donnée à caractère personnel, toute information se rapportant à une personne 
                            physique identifiée ou identifiable (ci-après dénommée «personne concernée») ; est réputée être 
                            une «personne physique identifiable» une personne physique qui peut être identifiée, 
                            directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un 
                            numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs 
                            éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, 
                            économique, culturelle ou sociale.</p>
                            <p><b>DIET AND FITNESS (D-FIT)</b> s'engage à ce que cette politique, ainsi que la façon dont les données 
                            des utilisateurs sont traitées en pratique, soient conformes à la Loi informatique et libertés 
                            modifiée, de même qu'au Règlement Général sur la Protection des Données (ou RGPD).</p>
                        </div>

                        <div style={{marginTop: 30}}>
                            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>ARTICLE 2. DONNÉES COLLECTÉES</h5>
                            <p>1. Chaque formulaire de collecte de données présent sur les sites DIET AND FITNESS (D-FIT) limite 
                            la collecte des données au strict nécessaire pour les services proposés. Ces données sont collectées dans le cadre indiqué ci-dessous (article 3).Des données supplémentaires peuvent toutefois être collectées par DIET AND FITNESS (D-FIT) si 
                            l'utilisateur choisit de remplir les champs libres laissés à sa disposition sur le site.</p>
                            <p>2. Certaines données doivent être obligatoirement communiquées, pour que l'utilisateur puisse 
                            accéder aux services proposés par <b>DIET AND FITNESS (D-FIT)</b> sur ses sites Internet.</p>
                        </div>

                        <div style={{marginTop: 30}}>
                            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>ARTICLE 3. MODALITES DE LA COLLECTE</h5>
                            <p>Nous n’utilisons vos données personnelles que dans les cas prévus par la réglementation en 
                            vigueur :</p>
                            <ul style={{color: 'black'}}>
                                <li>* L'exécution d'un contrat que nous avons conclu avec vous,</li>
                                <li>* Le respect d'une obligation légale,</li>
                                <li>* Votre consentement à l'utilisation de vos données</li>
                                <li>* L’existence d’un intérêt légitime à utiliser vos données. L’intérêt légitime est un ensemble de 
                                raisons commerciales ou d’affaires qui justifie l’utilisation de vos données par la société éditrice 
                                de <b>DIET AND FITNESS (D-FIT).</b></li>
                            </ul>
                            <p>Ces finalités reposent sur l’intérêt légitime de <b>DIET AND FITNESS (D-FIT)</b> de disposer de données 
                            concernant ses utilisateurs et ses contacts.</p>
                            <h6>DESTINATAIRES DE LA COLLECTE</h6>
                            <p>Les données sont destinées aux services de la société, afin de répondre à votre demande 
                            (marketing, clients …) ou conservées dans le cadre de la relation contractuelle.</p>
                            <h6>DUREE DE CONSERVATION</h6>
                            <p>Les données sont conservées et utilisées pour une durée conforme à la législation en vigueur.</p>
                        </div>

                        <div style={{marginTop: 30}}>
                            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>ARTICLE 4. UTILISATION DES DONNEES COLLECTEES</h5>
                            <p><b>DIET AND FITNESS (D-FIT)</b> peut utiliser les données à caractère personnel dont il est en possession 
                            afin de :</p>
                            <p>* envoyer des informations commerciales relatives à ses produits, ses promotions, ses offres, 
                            ainsi que d’autres informations ayant trait à ses produits ou services adaptées aux centres 
                            d’intérêts des personnes concernées ;</p>
                            <p>* transmettre des informations sur les produits et les offres de tiers – clients ou partenaires 
                            commerciaux de <b>DIET AND FITNESS (D-FIT)</b> – en relation avec la fonction et/ou au regard d’un 
                            intérêt identifié par rapport à l’activité de la personne concernée ou celle de l’organisation à 
                            laquelle elle appartient ;</p>
                            <p>Ces données à caractère personnel seront utilisées par DIET AND FITNESS (D-FIT) dans le cadre de 
                            ses activités relevant de la promotion de ses propres produits et services comme de la 
                            prospection pour le compte de tiers. Elles ne sont utilisées que dans les strictes limites définies 
                            par la législation en vigueur</p>
                        </div>

                        <div style={{marginTop: 30}}>
                            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>ARTICLE 5. DROITS DE L'UTILISATEUR SUR SES DONNÉES</h5>
                            <p>En vertu de la Loi informatique et libertés modifiée, ainsi que du RGPD, l'utilisateur dispose des droits suivants sur ses données :</p>
                            <p>* Le droit de demander l'accès aux données traitées</p>
                            <p>* Le droit de dmeander la rectification ou l'effacement des données traitées</p>
                            <p>* Le droit à une limitation du traitement relatif à ses données</p>
                            <p>* Le droit de s'opposer au traitement de ses données</p>
                            <p>* Le droit à la portabilité de ses données</p>
                            <p>* Le droit d'introduire une réclamation</p>
                        </div>

                        <div style={{marginTop: 30}}>
                        <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>ARTICLE 6. MODIFICATION DE LA PRESENTE POLITIQUE</h5>
                        <p>Nous adaptons et actualisons régulièrement les présentes informations sur la protection des 
                        données. En cas de modifications significatives qui pourraient concerner des droits d'utilisateurs 
                        de <b>DIET AND FITNESS (D-FIT),</b> nous communiquerons ces modifications au préalable. Ces 
                        informations sont fournies sous forme écrite.</p>
                        <p>Si vous avez d'autres questions sur la présente déclaration de protection des données, ou si vous
                        souhaitez exercer votre droit d'accès aux données vous concernant et les faire rectifier ou 
                        supprimer, vous pouvez nous contacter à l’adresse suivante : <a href="mailto:contact@sabafrique.com">contact@sabafrique.com</a></p>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    )
}

export default Policy
