import React, { useState } from 'react'
import Banner from './Banner'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';

function ContactContainer() {
    const [forms, setForms] = useState({
        nom: '',
        email: '',
        objet: '',
        tel: '',
        message: ''
    });
    const [loading, setLoading] = useState(false)
    const [messageBtn, setMessageBtn] = useState("Envoyer")

	const handleSubmit = async (e) => {
		e.preventDefault()
        setLoading(true)
        if (forms.email === '' || 
            forms.nom === '' || 
            forms.message === '' || 
            forms.objet === '' || 
            forms.tel === ''
        ) {
            toast.error("Veuillez remplir tous les champs")
            setLoading(false)
        } else {
            setMessageBtn("Traitement...")
            emailjs.send('service_dygo4el', 'template_p9z8kms', forms, 'AXrOEKNOrRm1s8_wI')
            .then((result) => {
                if (result.status === 200) {
                    setLoading(false)
                    toast.success("Le formulaire a été envoyé")
                    setForms({
                        nom: '',
                        email: '',
                        objet: '',
                        tel: '',
                        message: ''
                    })
                    setMessageBtn("Envoyer")
                } else {
                    setLoading(false)
                    toast.error("Une erreur est survenue")
                    setMessageBtn("Envoyer")
                }
            }, (error) => {
                toast.error(error)
                setLoading(false)
            })
        }
	}

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget
        setForms({...forms, [name]: value})
    }

    return (
        <main>
            <ToastContainer />
            {/* <!-- banner-section --> */}
            <Banner title="Contact" subtitle="Contact" />

			{/* Formulaire de contact */}
            <section id="contact" className="pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="section-title text-center mb-80">
                                <span>contact</span>
                                <h2>Contactez-nous</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="contact-wrapper">
                                <form onSubmit={handleSubmit} id="contact-form">
                                    <div className="row">
                                        <div className="col-lg-6 mb-30">
                                            <div className="contact-name c-icon">
                                                <i className="fas fa-user"></i>
                                                <input 
                                                    id="name" 
                                                    name='nom'
                                                    value={forms.nom}
                                                    onChange={handleChange} 
                                                    placeholder="Votre nom :" 
                                                    type="text" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-30">
                                            <div className="contact-email c-icon">
                                                <i className="fas fa-envelope"></i>
                                                <input 
                                                    id="email" 
                                                    name='email' 
                                                    value={forms.email}
                                                    onChange={handleChange} 
                                                    placeholder="Votre adresse mail :" 
                                                    type="email" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-30">
                                            <div className="contact-phone c-icon">
                                                <i className="fas fa-phone"></i>
                                                <input 
                                                    id="phone" 
                                                    name='tel' 
                                                    value={forms.tel}
                                                    onChange={handleChange} 
                                                    placeholder="Votre téléphone :" 
                                                    type="text" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-30">
                                            <div className="contact-phone c-icon">
                                                <i className="fas fa-sticky-note"></i>
                                                <input 
                                                    id="subject" 
                                                    name='objet' 
                                                    value={forms.objet}
                                                    onChange={handleChange} 
                                                    placeholder="L'objet du message :" 
                                                    type="text" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-30">
                                            <div className="contact-message c-icon">
                                                <i className="fas fa-pencil-alt"></i>
                                                <textarea 
                                                    id="message" 
                                                    name='message' 
                                                    value={forms.message}
                                                    onChange={handleChange} 
                                                    cols="30" rows="10" 
                                                    placeholder="Votre message :"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-butoon text-center mt-20">
                                                <button className="btn" type="submit" disabled={loading}>{messageBtn}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- counter-area --> */}
            <div className="contact-area pt-120 pb-90 mb-4" style={{backgroundImage: "url(../../public/assets/img/map.png)", backgroundSize: "cover"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                           <div className="about-title second-atitle">							
                                    <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Contact</span>
                                    <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Infos utiles de l'évènement</h2>
                                </div>
								<div className="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
							<div className="icon">
								<i className="fal fa-map-marker-alt"></i>
							</div>
							<div className="content">
								<h5>Localisation</h5>
								<p style={{fontSize: 18}}>Starland Hôtel</p>
								<p>85 rue Dominique Savio Primaire Bp: 5485, Douala, Cameroun</p>
                                <p style={{fontSize: 18}}>D-FIT</p>
                                <p>AKWA nouvelle route Bonakouamouang</p>
                                <p style={{fontSize: 18}}>THEPLUG</p>
                                <p>Logpom ancien ENEO après Tradex</p>
							</div>
						 </div>
						<div className="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
							<div className="icon">
								<i className="far fa-ticket-alt"></i>
							</div>
							<div className="content">
								<h5>Informations de réservation</h5>
								<p>
									Nom: Salon de l'Alimentation et du Bien-être (SAB)<br />
									Téléphone DFIT : <a href="tel:+237659409077">+237 659409077</a> / <a href="tel:+237650332739">+237 650332739</a><br />
									Email: <a href="mailto:hello@sabafrique.com">hello@sabafrique.com</a><br />
                                    Téléphone THEPLUG : <a href="tel:+237686117771">+237 686117771</a>
								</p>
							</div>
						 </div>
						<div className="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
							<div className="icon">
								<i className="far fa-phone"></i>
							</div>
							<div className="content">
								<h5>Numéros de téléphone</h5>
								<p>
									+237 659409077<br />
									+237 650332739
								</p>
							</div>
						 </div>
						 {/* <div className="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
							<div className="icon">
								<i className="fal fa-home"></i>
							</div>
							<div className="content">
								<h5>Programme Details</h5>
								<p>Name: Rosalina D. Williamson<br />Phone: 009-215-5595<br />Email: info@epicexample.com</p>
							</div>
						 </div> */}
						 <div className="feature-box text-left wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
							<div className="icon">
								<i className="fal fa-globe"></i>
							</div>
							<div className="content">
								<h5>Site internet</h5>
								<p><a href="https://sabafrique.com/">www.sabafrique.com</a></p>
							</div>
						 </div>
						
                        </div>
                       <div className="col-lg-6 col-sm-12">
							<div className="contact-circal">
								<div className="in-circal1 item-zoom-inout"></div>
								<div className="in-circal2 item-zoom-inout"></div>
								<div className="in-circal3 item-zoom-inout"></div>
								<div className="in-circal4 item-zoom-inout"></div>
								<div className="in-circal5 item-zoom-inout"></div>
							</div>
                        </div>
                                             
                    </div>
                </div>
            </div><br /><br />
        </main>
    )
}

export default ContactContainer
