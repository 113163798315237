import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import Banner from "../../components/Banner";
import { BlogSidebar } from "../../components/blog-sidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { organizeComments, timeAgo } from "../../utils/utils";
import { createComment, getCommentsByPostId, getPostById } from "../../data/api";

export default function BlogDetails() {
    const { slug } = useParams(); // Récupère le slug depuis l'URL
    const [id, setId] = useState(slug.split('-')[0]);
    const [post, setPost] = useState(null);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [comments, setComments] = useState([]);


    const [loading, setLoading] = useState(true);
   

    useEffect(() => {
        // Simule une requête pour récupérer les détails du blog
        getPostById(id).then(({ success, message, data }) => {

            if (success) {
                setPost(data)

            }
        })
        getCommentsByPostId(id).then(({ success, message, data }) => {

            if (success) {
                setComments(organizeComments(data))
            }
        })
    }, [slug]);

    useEffect(() => {
        if (post) document.getElementById('content').innerHTML = post.content
    }, [post]);

    const [formData, setFormData] = useState({
        text: "",
        name: "",
        email: "",
    });

    const [errors, setErrors] = useState({
        text: "",
        name: "",
        email: "",
    });

    const [isLoading, setIsLoading] = useState(false);

    // Fonction de validation
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.text) {
            formErrors.text = "Le text est requis.";
            isValid = false;
        }

        if (!formData.name) {
            formErrors.name = "Le nom est requis.";
            isValid = false;
        }

        if (!formData.email) {
            formErrors.email = "L'email est requis.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "L'email est invalide.";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    // Fonction de gestion de la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setIsLoading(true);

            createComment({ ...formData, post_id: id, comment_id: selectedCommentId }).then((res) => {
                getCommentsByPostId(id).then(({ success, message, data }) => {

                    if (success) {
                        setComments(organizeComments(data))
                    }
                })
                setSelectedCommentId(null)
                setIsLoading(false);
                // Réinitialiser les champs après soumission
                setFormData({
                    text: "",
                    name: "",
                    email: "",
                });
            })

            // Simulation du délai de soumission
        }
    };

    // Fonction de gestion des changements dans les champs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const htmlCreateComment = (<div className="post-comments-form mb-50 mt-50">
        <div className="comment__wrap-title d-flex justify-content-between">
            <h5>{selectedCommentId ? `Repondez à ${comments.find((el) => el.id == selectedCommentId).name}` : `Faire un Commentaire`}</h5>
            {selectedCommentId && <a onClick={() => setSelectedCommentId(null)} style={{color:'red', fontSize:'30px'}}><i className="far fa-cancel"></i>X</a>}
        </div>
        <div className="comment-box">
            <form onSubmit={handleSubmit} className="comment__form">
                <div className="comment-field text-area mb-20">
                    <i className="fas fa-pencil-alt"></i>
                    <textarea
                        name="text"
                        id="text"
                        cols="30"
                        rows="10"
                        placeholder="Entrez votre commentaire...."
                        value={formData.text}
                        onChange={handleChange}
                    />
                    {errors.text && <p className="error-text">{errors.text}</p>}
                </div>
                <div className="comment-field mb-20">
                    <i className="far fa-user"></i>
                    <input
                        type="text"
                        name="name"
                        placeholder="Votre nom...."
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <p className="error-text">{errors.name}</p>}
                </div>
                <div className="comment-field mb-20">
                    <i className="fas fa-envelope"></i>
                    <input
                        type="email"
                        name="email"
                        placeholder="Votre Email...."
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <p className="error-text">{errors.email}</p>}
                </div>

                <button className="btn" type="submit" disabled={isLoading}>
                    {isLoading ? "Chargement..." : "Commenter"}
                </button>
            </form>
        </div>
    </div>)

    return (
        <React.Fragment>
            <Helmet>
                {post && <title>{post.title}</title>}
            </Helmet>

            <Header />

            <main>
                <Banner title="Détails du post" subtitle="Blog" />


                <section className="inner-blog b-details-p pt-120 pb-140 wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                    <div className="container">
                        <div className="row">
                            {post && <div className="col-lg-8">
                                <div className="blog-details-wrap">
                                    <div className="meta__info">
                                        <ul><li><a href="#"><i className="far fa-clock"></i>{timeAgo(post.created_at)}</a></li>
                                            <li><a href="#"><i className="far fa-eye"></i>{post.views} Vues</a></li>

                                            <li><a href="#"><i className="far fa-comments"></i>{comments.length} Commentaires</a></li>
                                        </ul>
                                    </div>
                                    <div id="content"></div>

                                    <div className="comment__wrap pb-45 mb-45">
                                        <div className="comment__wrap-title">
                                            <h5>Commentaires ({comments.length})</h5>
                                        </div>

                                    </div>


                                    {comments.map((comment, i) => <div key={i} className={`single__comment mb-35`} style={{
                                        marginLef: `${(comments[i - 1]?.comment_id == comment.id) && '30px'}`,
                                        marginLeft: `${comment.comment_id && '30px'}`
                                    }}>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>{comment.name}</h6>
                                                <span>{timeAgo(comment.created_at)}</span>
                                                <a className={`comment-reply ${selectedCommentId==comment.id&&' bg-primary'} `} onClick={() => setSelectedCommentId(comment.id)}><i className="fas fa-reply"></i>Repondre</a>
                                            </div>
                                            <p>{comment.text}</p>
                                        </div>
                                        {selectedCommentId==comment.id && htmlCreateComment}
                                    </div>)}
                                    {!selectedCommentId &&  htmlCreateComment}

                                </div>
                            </div>}

                            <BlogSidebar />
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    )
}