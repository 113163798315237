import React from 'react'

function BannerCountArea() {
    return (
        <div className="counter-area pt-120 pb-120" 
            style={{backgroundImage: "url(../../public/assets/img/counter_bg.jpg)", backgroundSize: "cover"}}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <div className="about-title second-atitle">							
                            <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">pourquoi rejoindre notre évènement</span>
                            <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Rejoins notre salon à sa 2e édition</h2>
                            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Quelques points forts</h5>
                        </div>
                        
                        <ul className="wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                            <li>
                                <img src="/assets/img/calendar.png" alt="img" /> 
                                <span>Préparez-vous à embrasser un mode de vie sain et épanouissant ! Le tout nouveau Salon de l'Alimentation Saine et du Bien-Être ouvrira bientôt ses portes, promettant une expérience unique pour tous ceux qui aspirent à un style de vie équilibré.</span>
                            </li>
                            <li>
                                <img src="/assets/img/comments.png" alt="img" /> 
                                <span>Que vous soyez un passionné de nutrition, un adepte du fitness ou simplement à la recherche d'un havre de paix, ce salon deviendra votre destination incontournable. Des experts chevronnés vous guideront vers des choix alimentaires judicieux et des pratiques favorisant un bien-être holistique.</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="single-counter text-center mb-30 cr1">
                            <div className="counter p-relative">
                                <span className="count">10</span>
                                <small>+</small>
                            </div>
                            <p>Partenaires</p>
                        </div>
                        <div className="single-counter text-center mb-30 cr3">
                            <div className="counter p-relative">
                                <span className="count">20</span>
                                <small>+</small>
                            </div>
                            <p>Intervenants</p>
                        </div>
                        <div className="single-counter text-center mb-30 cr2">
                            <div className="counter p-relative">
                                <span className="count">500</span>
                                <small>+</small>
                            </div>
                            <p>Personnes satisfaites</p>
                        </div>
                        <div className="cr4"></div>
                        <div className="cr5"></div>
                        <div className="cr6"></div>
                        
                    </div>                        
                </div>
            </div>
        </div>
    )
}

export default BannerCountArea
