import React from 'react'

function Pricing() {
    return (
        <section id="pricing" 
            className="pricing-area pt-113 pb-90 fix" 
            style={{backgroundImage: "url(../../public/assets/img/pricing_bg.jpg)", backgroundSize: "cover"}}
        >
			<div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                    <div className="section-title text-center mb-80">
                        <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Nos tarifs</span>
                        <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Obtenez votre ticket</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-box text-center mb-30 wow fadeInDown animated" 
                            data-animation="fadeInDown animated" data-delay=".2s"
                        >
                            <div className="pricing-head">                                    
                                <h4>Salon</h4>
                                <div className="price-count mb-30">
                                    <h3>5000<small style={{fontSize: '15px'}}>XAF</small></h3>
                                </div>
                            </div>
                            <div className="pricing-body mb-40" style={{width: '100%'}}>
                                <p>* Conférence</p>
                                <p>* Visite de stands</p>
                                <p>* Table ronde</p>
                                <div className="bar">
                                    <span style={{width: "14.29%"}}></span>
                                </div>
                                <div className="bar-no">5000/35000</div>
                                <div className="pricing-btn mt-40">
                                    <a href="/contact" className="btn"><i className="far fa-ticket-alt"></i>Acheter</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-box active text-center mb-30 wow fadeInDown animated" 
                            data-animation="fadeInDown animated" data-delay=".2s"
                        >
                            <div className="tag">En solde</div>
                            <div className="pricing-head">                                   
                                <h4>Marathon</h4>
                                <div className="price-count mb-30">
                                    <h3>5000<small style={{fontSize: '15px'}}>XAF</small></h3>
                                </div>
                            </div>
                            <div className="pricing-body mb-40">
                                <p>* 1 Marathon de 10km <b>(1 kit complet offert par TARA SPORT)</b></p>
                                <p>* Crêpes <b>(de Maman KAMITE)</b></p>
                                <p>* Salades, biscuits et cookies <b>(de My Cookies)</b></p>
                                <p>* Chasubles et bouteilles d'eau</p>
                                <p>* Salade offerte par <b>D-FIT</b></p>
                                <p>* Une tasse de café offerte par <b>Terrific Coffee</b></p>
                                <div className="bar">
                                    <span style={{width: "14.29%"}}></span>
                                </div>
                                <div className="bar-no">5000/35000</div>
                                <div className="pricing-btn mt-40">
                                    <a href="/contact" className="btn"><i className="far fa-ticket-alt"></i>Acheter</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-box text-center mb-30 wow fadeInDown animated" 
                            data-animation="fadeInDown animated" data-delay=".2s"
                        >
                            <div className="pricing-head">                                    
                                <h4>Cooking show</h4>
                                <div className="price-count mb-30">
                                    <h3>35000<small style={{fontSize: '15px'}}>XAF</small></h3>
                                </div>
                            </div>
                            <div className="pricing-body" style={{width: '100%'}}>
                                <p>* 1 atelier cuisine live <b>(2 recettes diéthétiques réalisées par Ndock Bidi)</b></p>
                                <p>* Buffet</p>
                                <p>* Diner privé</p>
                                <div className="bar">
                                    <span style={{width: "100%"}}></span>
                                </div>
                                <div className="bar-no">35000/35000</div>
                                <div className="pricing-btn mt-40">
                                    <a href="/contact" className="btn"><i className="far fa-ticket-alt"></i>Acheter</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing
