import { useEffect, useState } from 'react'
import { articles } from '../data/articles'
import { getAllPosts } from '../data/api'
import { Link, useParams } from 'react-router-dom'
export const BlogSidebar = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const { slug } = useParams(); 
    const [id, setId] = useState(slug?.split('-')[0]??0);
    useEffect(() => {
        getAllPosts().then(({ success, message, data }) => {
            if (data) setPosts(data)

        })
    }, [])
    return (
        <div className="col-lg-4">
            <aside>
                {/* <div className="widget mb-40">
                    <div className="widget-title text-center">
                        <h4>Recherche</h4>
                    </div>
                    <div className="slidebar__form">
                        <form action="#">
                            <input type="text" placeholder="Search your keyword..." />
                            <button><i className="fas fa-search"></i></button>
                        </form>
                    </div>
                </div> */}
                {/* <div className="widget mb-40">
                    <div className="widget-title text-center">
                        <h4>Instagram</h4>
                    </div>
                    <ul className="widget-insta-post">
                        <li><a href="#"><img src="img/blog/aside/insta_01.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_02.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_03.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_04.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_05.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_06.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_07.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_08.jpg" alt="img" /></a></li>
                        <li><a href="#"><img src="img/blog/aside/insta_09.jpg" alt="img" /></a></li>
                    </ul>
                </div> */}
                <div className="widget mb-40">
                    <div className="widget-title text-center">
                        <h4>Suivez-nous</h4>
                    </div>
                    <div className="widget-social text-center">
                        <a target='_blank' href="https://x.com/sab_afrique"><i className="fab fa-twitter"></i></a>

                        <a target='_blank' href="https://www.facebook.com/sab.cameroun"><i className="fab fa-facebook-f"></i></a>
                        <a target='_blank' href="https://www.instagram.com/sab.afrique_/"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
                {/* <div className="widget mb-40">
                    <div className="widget-title text-center">
                        <h4>Categories</h4>
                    </div>
                    <ul className="cat__list">
                        <li><a href="#">Lifestyle <span>(05)</span></a></li>
                        <li><a href="#">Travel <span>(34)</span></a></li>
                        <li><a href="#">Fashion <span>(89)</span></a></li>
                        <li><a href="#">Music <span>(92)</span></a></li>
                        <li><a href="#">Branding <span>(56)</span></a></li>
                    </ul>
                </div> */}
                <div className="widget mb-40">
                    <div className="widget-title text-center">
                        <h4>Articles et presses</h4>
                    </div>
                    <div className="widget__post">
                        <ul>
                            {articles.map((p, i) => <li key={i}>
                                <div className="widget__post-thumb">
                                    <img src="img/blog/aside/post_01.jpg" alt="" />
                                </div>
                                <div className="widget__post-content">
                                    <h6><a href={p.link}>{p.title}</a></h6>
                                    <span><a target='_blank' href={p.linkProvider}><i className="far fa-user"></i>by {p.provider}</a></span>
                                </div>
                            </li>)}

                        </ul>
                    </div>
                </div>


                <div className="related__post mt-45 mb-85">
                    <div className="post-title">
                        <h4>Autres posts</h4>
                    </div>
                    <div className="row">
                        {posts.filter((post)=>post.id!=id).slice(0, 2).map((post) => <div key={post.id} className="col-12">
                            <Link to={`/blog/${post.id}-${post.slug}`}><div className="related-post-wrap mb-30">
                                <div className="post-thumb">
                                    <img src={post.cover} alt="" />
                                </div>
                                <div className="rp__content">
                                    <h3><a>{post.title}</a></h3>
                                    {/* <p>{post.description}</p> */}
                                </div>
                            </div></Link>
                        </div>)}

                    </div>
                </div>
            </aside>
        </div>
    );
}