import React from 'react'
import About from './About'
import ProgramsEvent from './ProgramsEvent'
import Sponsors from './Sponsors'
import BannerCountArea from './BannerCountArea'
import Pricing from './Pricing'
import PartnersContainer from './PartnersContainer'


function HomeContainer() {
    return (
        <main>
			<div className='video-background'>
				<video autoPlay loop muted>
					<source src="/assets/video/Mix.mp4" type='video/mp4'/>
				</video>
			</div>

			<div className="container mt-4 pt-20">
				<div className="row">
					<div className="col-lg-12 col-sm-12">
						<div className="slider-content second-slider-content">
							<ul data-animation="fadeInUp animated" data-delay=".2s">
								<li style={{color: 'black'}}><i className="fas fa-map-marker-alt"></i> STARLAND Hotel, Douala</li>
								<li style={{color: 'black'}}><i className="far fa-clock"></i>  7 - 10 Novembre 2024 </li>
								<li style={{color: 'black'}}><i className="fal fa-building"></i>2°  Edition </li>
							</ul>
							<h2 style={{color: '#118A3C'}} className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".4s">Salon de l'Alimentation et du Bien-être(SAB)</h2>
							{/* <div className="conterdown wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s" countdown data-date="Nov 7 2024 08:00:00">
								<div className="timer">										 
								<div className="timer-outer bdr1">												
									<span className="days" data-days>170</span> 
									<div className="smalltext">Days</div>
									<div className="value-bar"></div>
								</div>
								<div className="timer-outer bdr2">
									<span className="hours" data-hours>0</span> 
									<div className="smalltext">Hours</div>
								</div>
								<div className="timer-outer bdr3">
									<span className="minutes" data-minutes>0</span> 
									<div className="smalltext">Minutes</div>
								</div>
								<div className="timer-outer bdr4">
									<span className="seconds" data-seconds>0</span> 
									<div className="smalltext">Seconds</div>
								</div>
								<p id="time-up"></p>
								</div>
							</div> */}
						</div>
					</div>
					{/* <div className="col-lg-4 col-sm-12">
						<div className="booking-form mt-50 align-items-center justify-content-center wow fadeInLeft" data-animation="fadeInLeft" data-delay=".2s">
							<h2>S'enregistrer dès maintenant !</h2>
							<form>
								<div className="form-outer">
									<div className="icon"><i className="fal fa-user"></i></div>
									<input type="text" placeholder="Entrer votre nom"/>
								</div>
								<div className="form-outer">
									<div className="icon"><i className="fal fa-envelope"></i></div>
									<input type="email" placeholder="Entrer votre email"/>
								</div>
								<div className="form-outer">
									<div className="icon"><i className="far fa-phone"></i></div>
									<input type="text" placeholder="Entrer votre téléphone"/>
								</div>
								<div className="form-outer">
									<div className="icon"><i className="fal fa-list"></i></div>
									<select id="select">
										<option selected="selected">Combien seriez-vous ?</option>
										<option value={1}>1</option>  
										<option value={2}>2</option>  
										<option value={3}>3</option>  
										<option value={4}>4</option>  
										<option value={5}>5</option>  
									</select>										
								</div>
								<div className="text-center">
									<a href="/" className="btn"><i className="far fa-ticket-alt"></i>S'inscrire</a>
								</div>
							</form>
						</div>
					</div> */}
				</div>
			</div>
            
            <About />
            
            {/* <Speakers /> */}
            
            {/* <!-- counter-area --> */}
            <BannerCountArea />
            
			{/* <!-- event --> */}
            <ProgramsEvent />
            
			{/* <!-- pricing-area --> */}
            <Pricing />

			{/* Partners Description container */}
			<PartnersContainer />

			{/* Devenez sponsor */}
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-8">
					<div className="section-title text-center mb-80">
						<span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Devenez Sponsor</span>
						<p className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Que ce soit pour renouveler des amitiés, développer des réseaux professionnels ou affirmer vos 
						engagements sociétaux, nos programmes, taillés sur mesure sauront répondre à vos désirs les plus 
						inavoués. Contactez-nous et recevez une copie du dossier marketing du SAB AFRIQUE 2024.
						</p>
					</div>
				</div>
			</div>

			{/* Devenez partenaire */}	
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-8">
					<div className="section-title text-center mb-80">
						<span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Devenez Partenaire</span>
						<p className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Pour sa deuxième édition au sein du triangle national, le SAB AFRIQUE lance un appel à partenariats 
							adressé aux entreprises, institutions, particuliers etc. souhaitant nourrir notre ambition de faire vibrer 
							l’Afrique en dialogue avec d’autres cultures, pour un impact sociétal durable contribuant à la valorisation 
							des écosystèmes de l’Alimentation et du Bien- Être
						</p>
					</div>
				</div>
			</div>
            
            {/* <!-- Sponsors-area --> */}
            <Sponsors />
           
            {/* <!-- blog-area --> */}
            {/* <BlogList /> */}
        </main>
    )
}

export default HomeContainer
