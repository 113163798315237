import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Banner from '../components/Banner'
import Footer from '../components/Footer'

function Conditions() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Conditions générales - SAB Afrique</title>
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>

      <Header />

      <main>
        <Banner subtitle="Conditions Générales" title="Conditions Générales" />
        <section className="mt-80 mx-5 mb-80">
          <h4 className="wow fadeInUp animated text-center mb-4" data-animation="fadeInUp animated" data-delay=".2s">CONDITIONS GENERALES DE VENTE</h4>
          <div style={{marginTop: 30}}>
            <p>Toutes les commandes effectuées sur le site sont soumises aux présentes conditions générales 
            de vente. La société ci-nommée préalablement se réserve le droit d’adapter ou de modifier à tout 
            moment les présentes conditions générales de vente figurées sur le site internet : 
            www.sabafrique.com</p>
            <p>Ce sont ces dernières, figurant en ligne au moment de la commande qui seront applicables à toute 
            transaction.</p>
            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>1. Le client</h5>
            <p>Le Client déclare être une personne physique, âgée d’au moins 21 ans et avoir la capacité juridique 
            ou être titulaire d’une autorisation parentale lui permettant d’effectuer une commande sur le 
            site. Lors de l’enregistrement des données personnelles du Client dans la rubrique “mon compte”, 
            ce dernier doit s’assurer de l’exactitude et de l’exhaustivité des données obligatoires qui lui sont 
            demandées. En cas d’erreur dans le libellé des coordonnées du destinataire, les administrateurs 
            du site cedricobline.com ne sauraient être tenus responsables des difficultés et/ou de 
            l’impossibilité de livraison du produit. Le site
            sabafrique.com se réserve le droit d’annuler toute commande lorsque l’adresse IP du client sera 
            domiciliée dans un pays différent de l’adresse de facturation et/ou de livraison.</p>
            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>2. Le produit</h5>
            <p>www.sabafrique.com apporte le plus grand soin à la présentation et à la description de ces produits pour satisfaire
              l'information du Client et le guider au mieux. Les informations relatives aux articles apparaissent sur chacune de nos fiches produits.
            </p>
          </div>

          <div style={{marginTop: 30}}>
            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>3. La commande</h5>
            <p>La prise de commande sur cedriconline.com est soumise au respect des procédures mises en 
            place par les administrateurs du site. Elles sont concrétisées par une succession d’étapes 
            obligatoires à suivre pour valider la commande du client.</p>
            <p>Lors de la première commande via Internet, les nouveaux clients doivent créer un «compte 
            client», un identifiant («login»). Un mot de passe sera demandé. Un courriel généré 
            automatiquement par notre site Web à l’adresse e-mail lors de la création de votre compte.</p>
            <p>A la validation de votre panier, vous serez dirigé vers votre compte via une connexion sécurisée 
            rappelant le contenu de votre commande. Il vous ai demandé de choisir un mode de règlement 
            puis de confirmer vos adresses de facturation et livraison.</p>
            <p>Nous vous demandons d’être attentifs quant à l’exactitudes des informations fournies. Votre 
            commande ne sera définitive qu’après avoir cliqué sur l’onglet VALIDATION FINALE.</p>
            <p>Lorsque vous enregistrez la commande, La vente est considérée comme conclue (accord sur les 
              marchandises et le prix).</p>
            <p>Un email de confirmation récapitulant la commande (produits, prix, disponibilité des produits, 
            quantité…) sera adressé au client par le site cedriconline.com. A cet effet, le client accepte 
            formellement l’usage du courrier électronique pour la confirmation du contenu de sa commande.</p>
            <p>Les informations que vous fournissez restent confidentielles. Nous sommes chargés de protéger 
            votre vie privée.</p>
            <p>Nous n’utiliserons pas les informations que nous recueillons à votre sujet légalement et 
            conformément à la Loi sur la protection des données de 1998.</p>
            <p>Nous recueillons des informations vous concernant pour deux raisons : premièrement, traiter 
            votre commande et, deuxièmement, vous offrir le meilleur service possible. Vous nous autorisez 
            expressément à transmettre des informations ou à obtenir des informations vous concernant 
            auprès de tiers (y compris votre numéro de carte de crédit) afin de confirmer votre identité, de 
            valider votre carte de crédit et autoriser la transaction.</p>
            <p>Vous reconnaissez que vous consentez au traitement de ces informations. N’hésitez pas à nous 
            contacter pour toutes questions relatives à notre politique de confidentialité.</p>
          </div>

          <div style={{marginTop: 30}}>
            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>4. Prix et modalités de paiement</h5>
            <h6 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>4.1. Affichage des prix</h6>
            <p>Les prix des produits sont indiqués en CFA, toutes taxes comprises, frais de port inclus. Le prix total de la commande est indiqué dans le panier.</p>
            <p>Le site sabafrique.com se réserve le droit de modifier ses prix à tout moment mais les produits 
            seront facturés sur la base des tarifs en vigueur au moment de l’enregistrement de la commande, 
            sous réserve de disponibilité.</p>
            <h6 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>4.2. Modalités de paiement</h6>
            <p>Les produits sont payables comptant le jour de la commande effective, via Orange Money, MTN 
            Mobile Money, à la livraison, ou par tout autre voie légale.</p>
            <h6 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>4.3. Preuves de paiement</h6>
            <p>Les données enregistrées et conservées par le site cedriconline.com constituent la preuve de la 
            commande et de l’ensemble des transactions passées. Les données enregistrées par le système 
            de paiement constituent la preuve des transactions financières.</p>
          </div>

          <div style={{marginTop: 30}}>
            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>5. Livraison</h5>
            <p>La livraison est effectuée à l’adresse de livraison indiquée par le client étant précisé que celle-ci 
            doit être l’adresse de résidence du client ou de toute autre personne physique de son choix. Les 
            délais vont de 24h à 20 jours selon la destination.</p>
            <p>Afin que ces délais soient respectés, le client doit s’assurer d’avoir communiqué des informations 
            exactes et exhaustives concernant l’adresse de livraison (tels que, notamment : n° de rue, de 
            bâtiment, d’escalier, codes d’accès, noms et/ou numéros d’interphone, etc.).</p>
            <p>Les délais indiqués sont des délais indicatifs, correspondant aux délais moyens de traitement et 
            de livraison. Le site cedriconline.com ne pourra être tenu responsable des conséquences dues à 
            un retard d’acheminement n’étant pas de son fait ou à la perte de tout envoi.</p>
            <p>A la réception, le client doit donc vérifier la conformité du colis, de la commande. Le client s’assure 
            de l’accessibilité du lieu de livraison. Si la livraison n’est pas possible du fait d’un problème d’accès 
            que le client n’aura pas porté à la connaissance de sabafrique.com lors de la commande et que le 
            colis est renvoyé directement, le client supportera seul les frais de présentation du transporteur 
            ainsi que les frais de retour. Ceux-ci seront déduits directement du montant de la commande 
            remboursée au Client. Si la livraison est opérée par un transporteur en l’absence du client, un avis 
            de passage est déposé. Les instructions à suivre pour récupérer le colis sont mentionnées sur cet 
            avis. Sabafrique.com ne peut être tenu pour responsable en cas de mauvaise interprétation ou 
            de non-respect de ces instructions.</p>
          </div>

          <div style={{marginTop: 30}}>
            <h5 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{color: "#B7C031"}}>6. Droit applicable</h5>
            <p>Toute commande emporte de plein droit adhésion du client aux Conditions Générales de Vente. 
            Ces conditions générales de vente sont régies par le droit français. En cas de litige, seuls les 
            Tribunaux français seront compétents. En cas de difficulté ou de réclamation à l’occasion d’une 
            commande, le client peut s’adresser à l’équipe du site via contact@sabafrique.com ou au +237(0)
            659409077 pour trouver une solution amiable.</p>
          </div>
        </section>
      </main>

      <Footer />
    </React.Fragment>
  )
}

export default Conditions
