export function timeAgo(date) {
    const now = new Date();
    const givenDate = new Date(date);
    const diffInSeconds = Math.floor((now - givenDate) / 1000);
  
    if (diffInSeconds < 60) {
      return `il y a ${diffInSeconds} secondes`;
    }
  
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `il y a ${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
    }
  
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `il y a ${diffInHours} heure${diffInHours > 1 ? 's' : ''}`;
    }
  
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
      return `il y a ${diffInDays} jour${diffInDays > 1 ? 's' : ''}`;
    }
  
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return `il y a ${diffInMonths} mois`;
    }
  
    const diffInYears = Math.floor(diffInMonths / 12);
    return `il y a ${diffInYears} an${diffInYears > 1 ? 's' : ''}`;
  }
  
 export function organizeComments(comments) {
    // Créer une map des commentaires par leur id
    const commentMap = new Map();
    comments.forEach((comment) => {
        commentMap.set(comment.id, { ...comment, replies: [] });
    });

    // Lier les réponses à leur commentaire parent
    const organizedComments = [];
    comments.forEach((comment) => {
        if (comment.comment_id === null) {
            organizedComments.push(commentMap.get(comment.id));
        } else if (commentMap.has(comment.comment_id)) {
            commentMap.get(comment.comment_id).replies.push(commentMap.get(comment.id));
        }
    });

    // Transformer la structure plate en liste organisée
    const flattenComments = (comments) => {
        const result = [];
        comments.forEach((comment) => {
            const { replies, ...rest } = comment;
            result.push(rest);
            if (replies.length > 0) {
                result.push(...flattenComments(replies));
            }
        });
        return result;
    };

    return flattenComments(organizedComments);
}
