import React from 'react'

function Banner({title, subtitle}) {
    return (
        <section id="parallax" 
            className="slider-area breadcrumb-area d-flex align-items-center justify-content-center fix" 
            style={{backgroundImage: "url(../../public/assets/img/innerpage_bg_img.jpg)"}}
        >
            <div className="slider-shape ss-one layer" data-depth="0.10"><img src="/assets/img/doddle_6.png" alt="shape" /></div>
            <div className="slider-shape ss-three layer" data-depth="0.40"><img src="/assets/img/doddle_9.png" alt="shape" /></div>
            <div className="slider-shape ss-four layer" data-depth="0.60"><img src="/assets/img/doddle_7.png" alt="shape" /></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                        <div className="breadcrumb-wrap text-center">
                            <div className="breadcrumb-title mb-30">
                                <h2>{title}</h2>                                   
                            </div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{subtitle}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
