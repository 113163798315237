import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Header() {
    const [display, setDisplay] = useState(false)
    const screenWidth = window.screen.width

    useEffect(() => {
        const btnMenu = document.querySelector('.responsive')
        const linksLi = document.querySelectorAll('li.has-sub')
        
        btnMenu.addEventListener('click', () => {
            setDisplay(!display)
        })

        linksLi.forEach((li) => {
            li.addEventListener('click', () => {
                const previousActive = document.querySelector('li.active')
                if (previousActive) {
                    previousActive.classList.remove('active')
                }
                li.classList.add('active')
            })
        })
    }, [display])

    return (
        <header id="home" className="header-area">            
            <div id="header-sticky" className="menu-area">
                <div className="container">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3">
                                <div className="logo">
                                    <Link to="/"><a><img width={100} height={30} src="/assets/img/logo/logo5.png" alt="logo" /></a></Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-9">
                                <div className="responsive"><i className="icon dripicons-align-right"></i></div>
                                <div className="main-menu text-right text-xl-center">
                                    <nav id="mobile-menu" style={{display: display || screenWidth > 900 ? 'block' : 'none'}}>
                                        <ul>
                                            <li className="has-sub">
                                                <Link to="/home"><a>Accueil</a></Link>
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/a-propos"><a>A Propos</a></Link>
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/activites"><a>Nos actions+</a></Link>
                                                <ul>
                                                    <li className="has-sub"><Link to="/activites"><a>Activités</a></Link></li>
                                                    <li className="has-sub"><Link to="/press-book"><a>Articles & Presse</a></Link></li>
                                                </ul>												
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/galerie"><a>Galerie+</a>	</Link>
                                                <ul>
                                                    <li className="has-sub"><Link to="/galerie/2023"><a>2023</a></Link></li>
                                                    <li className="has-sub"><Link to="/galerie/2024"><a>2024</a></Link></li>
                                                </ul>											
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/d-fit"><a>DFIT</a></Link>
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/blog"><a>Blog</a></Link>
                                            </li>
                                            <li className="has-sub">
                                                <Link to="/contact"><a>Contact</a></Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-3 text-right d-none d-xl-block">
                                <div className="header-btn second-header-btn">
                                    <Link to="/contact" className="btn"><a><i className="far fa-ticket-alt"></i> Devenir sponsor</a></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
